import React from 'react';
import './Mentorslistmodalbox.css';
import { useTriggers } from '../../hooks/navigation';
import { useSelector } from 'react-redux';
import useMentors from '../../hooks/api/mentors/useMentors';
import Loader from '../../modules/loader';
import MentorPagination from '../../modules/mentors/pagination';
import {
  handleLayoutsWithCustomTooltipsJS,
  textTruncate,
  userIsOnTrial,
} from '../../utils/shared';
import NoRecordFound from '../NoRecordFound/NoRecordFound';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';
import { useTranslation } from 'react-i18next';
import { useDebounce } from '../../hooks/useDebounce';
import IBLPagination from '../../modules/pagination/IBLPagination';

const Mentorslistmodalbox = (props) => {
  const {
    handleModalCloseClick,
    handleCreateMentorBtnClick,
    handleOpenFreeTrialModal,
  } = useTriggers();
  const auth = useSelector((state) => state.auth.data);
  const {
    loading,
    error,
    page,
    handleSetPage,
    numPages,
    mentorsToDisplay,
    handleSearchChange,
    handleFeaturedStateChanged,
    handleMentorItemSelect,
    hasLoadedOnce,
  } = useMentors();

  useDebounce(handleLayoutsWithCustomTooltipsJS, 200, []);
  const TOOLTIP_TRUNCATE_LIMIT = 19;
  const mentors = useSelector((state) => state.mentors);
  const { t } = useTranslation();
  return (
    <div className="modal mentor-list-modal">
      <div className="w-layout-vflex modal-container mentor-list-modal-container">
        <div className="w-layout-hflex modal-header">
          <h3 className="modal-header-title">Mentors</h3>
          <div
            className="modal-close-wrapper"
            data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
            onClick={handleModalCloseClick}
            prop-events-value-onclick="handleModalCloseClick"
          >
            <img alt="" loading="lazy" src="/images/close_1close.png" />
          </div>
        </div>
        <div className="w-layout-vflex modal-body user-settings-modal-body">
          <div className="text-block-25">
            {t('Showing list of mentors used with the AI Mentor Pro')}
          </div>
          <div className="w-layout-hflex table-top-header-block">
            <div className="w-layout-hflex search-box-block all-whiten-in-dark-mode">
              <img
                alt=""
                className="image-49"
                loading="lazy"
                src="/images/search-1.svg"
              />
              <div className="search-box-form-block w-form">
                <form
                  data-name="Email Form 4"
                  data-wf-element-id="40a54b0a-56f4-f227-3a60-bdd130d6bc72"
                  data-wf-page-id="650305782bc61751e5765214"
                  id="email-form-4"
                  method="get"
                  name="email-form-4"
                >
                  <input
                    className="text-field-4 w-input"
                    data-name="Name 5"
                    id="name-5"
                    maxLength={256}
                    name="name-5"
                    onChange={handleSearchChange}
                    placeholder="Search mentors"
                    type="text"
                  />
                </form>
              </div>
            </div>
            <div
              className="button-with-icon users-settings-invite-btn"
              data-w-id="40a54b0a-56f4-f227-3a60-bdd130d6bc7a"
              onClick={
                userIsOnTrial()
                  ? handleOpenFreeTrialModal
                  : handleCreateMentorBtnClick
              }
              prop-events-value-onclick="handleCreateMentorBtnClick"
            >
              <img
                alt=""
                className="button-icon"
                loading="lazy"
                src="/images/plus.svg"
                width={70}
              />
              <div className="button-text">{t('Create Mentor')}</div>
            </div>
          </div>
          <div className="w-layout-vflex table-container">
            <div className="w-layout-hflex table-header">
              <div className="table-block table-block-big all-whiten-in-dark-mode">
                <h5 className="table-title">{t('NAME')}</h5>
                <img
                  alt=""
                  className="user-table-sort-icon"
                  loading="lazy"
                  src="/images/arrow-up-down.svg"
                />
              </div>
              <div className="table-block table-block-medium all-whiten-in-dark-mode">
                <h5 className="table-title">{t('LLM')}</h5>
                <img
                  alt=""
                  className="user-table-sort-icon"
                  loading="lazy"
                  src="/images/arrow-up-down.svg"
                />
              </div>
              <div className="table-block table-block-big all-whiten-in-dark-mode">
                <h5 className="table-title">{t('DATASET')}</h5>
                <img
                  alt=""
                  className="user-table-sort-icon"
                  loading="lazy"
                  src="/images/arrow-up-down.svg"
                />
              </div>
              <div className="table-block table-block-very-large all-whiten-in-dark-mode">
                <h5 className="table-title">{t('SYSTEM PROMPT')}</h5>
                <img
                  alt=""
                  className="user-table-sort-icon hidden"
                  loading="lazy"
                  src="/images/arrow-up-down.svg"
                />
              </div>
              <div className="table-block table-block-small all-whiten-in-dark-mode">
                <h5 className="table-title text-center">{t('FEATURED')}</h5>
              </div>
            </div>

            {loading && <IBLSpinLoader size={30} containerHeight={'100px'} />}
            {hasLoadedOnce && !loading && !mentorsToDisplay?.length && (
              <NoRecordFound />
            )}
            {!loading &&
              mentorsToDisplay?.map((mentor) => (
                <div
                  key={mentor.slug}
                  className="w-layout-hflex table-body-row greyish-bg-in-darkmode"
                >
                  <div className="table-block table-block-big bold-in-dark-mode">
                    <a
                      className="table-text-link w-inline-block has-custom-tooltip"
                      data-w-id="7a4016ba-caa2-f34f-0fba-f8b9fe1390e5"
                      onClick={() => handleMentorItemSelect(mentor)}
                      href="#"
                    >
                      <div className="table-text">
                        {textTruncate(mentor.name, TOOLTIP_TRUNCATE_LIMIT)}
                      </div>
                      {String(mentor.name).length > TOOLTIP_TRUNCATE_LIMIT && (
                        <div className="tooltip table-tooltip">
                          {mentor?.name}
                        </div>
                      )}
                    </a>
                    <div className="w-layout-hflex anonymous-switch-container">
                      <div
                        className="w-layout-hflex anonymous-switch-block"
                        data-w-id="148b2828-6bbe-7c25-b974-4145b5c6f1b1"
                      >
                        <img
                          alt=""
                          className="anonymous-switch-icon"
                          loading="lazy"
                          src="/images/eye-off-2.svg"
                        />
                        <div className="tooltip anonymous-mode">
                          {t('Activate Anonymous Mode')}
                        </div>
                      </div>
                      <div
                        className="w-layout-hflex anonymous-switch-block is-anonymous hidden"
                        data-w-id="a2faab08-5a84-326d-c2f2-09ed5a94477f"
                      >
                        <img
                          alt=""
                          className="anonymous-switch-icon img-whiten"
                          loading="lazy"
                          src="/images/eye-1.svg"
                        />
                        <div className="tooltip anonymous-mode deactivate">
                          {t('Deactivate Anonymous Mode')}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-block table-block-medium">
                    <div className="table-text">{mentor?.llm_name}</div>
                  </div>
                  <div className="table-block table-block-big">
                    <div className="table-text text-inline">
                      {mentor?.slug}
                      <br />
                    </div>
                  </div>
                  <div className="table-block table-block-very-large">
                    <div className="table-text text-inline">
                      <em>{mentor?.system_prompt}</em>
                      <br />
                    </div>
                  </div>
                  <div className="table-block table-block-small">
                    <div className="user-row-switcher-embed w-embed">
                      <label
                        className="user-switcher"
                        title={
                          !userIsOnTrial()
                            ? 'Toggle featured status'
                            : 'This feature is available in our paid tier'
                        }
                        style={{
                          cursor: !userIsOnTrial() ? 'pointer' : 'not-allowed',
                        }}
                      >
                        <input
                          disabled={userIsOnTrial()}
                          onChange={(event) =>
                            handleFeaturedStateChanged(
                              mentor,
                              event.target.checked
                            )
                          }
                          defaultChecked={
                            mentor?.settings?.mentor_visibility ===
                            'viewable_by_tenant_students'
                          }
                          type="checkbox"
                        />
                        <span className="user-switcher-slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {mentors?.mentors && (
            <div className="mentors-pagination">
              <IBLPagination
                currentPage={page}
                totalPages={numPages}
                onPageChange={handleSetPage}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Mentorslistmodalbox;
