import React from 'react';
import useMentorDataset from '../../../hooks/api/mentors/useMentorDataset';
import DatasetItem from './dataset-item';
import Loader from '../../loader';
import { useTriggers } from '../../../hooks/navigation';
import { userIsOnTrial } from '../../../utils/shared';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';
import NoRecordFound from '../../../components/NoRecordFound/NoRecordFound';
import Pagination from '../../pagination';
import IBLPagination from '../../pagination/IBLPagination';
import { usePageNumberPagination } from '../../../hooks/api/mentors/usePageNumberPagination';

export default function MentorDataset() {
  const {
    loading,
    datasetsToRender,
    datasets,
    search,
    setSearch,
    hasLoadedOnce,
    page,
    numPages,
    handlePageChange,
  } = useMentorDataset();
  const { handleOpenDatasetResourceTypes, handleOpenFreeTrialModal } =
    useTriggers();
  return (
    <div className="w-layout-vflex edit-mentor-dataset-tab">
      <div className="text-block-25">
        Showing list of datasets attached to this mentor
      </div>
      <div className="w-layout-hflex table-top-header-block">
        <div className="w-layout-hflex search-box-block all-whiten-in-dark-mode">
          <img
            alt=""
            className="image-49"
            loading="lazy"
            src="/images/search-1.svg"
          />
          <div className="search-box-form-block w-form">
            <form
              data-name="Email Form 4"
              data-wf-element-id="b2182b0b-09e9-b9e8-0941-94ce822aa70a"
              data-wf-page-id="650305782bc61751e5765214"
              id="email-form-4"
              method="get"
              name="email-form-4"
            >
              <input
                className="text-field-4 w-input"
                data-name="Name 5"
                id="name-5"
                maxLength={256}
                name="search"
                onChange={(event) => setSearch(event.target.value)}
                placeholder="Search datasets"
                type="text"
              />
            </form>
          </div>
        </div>
        <div
          className="button-with-icon users-settings-invite-btn"
          data-w-id="b2182b0b-09e9-b9e8-0941-94ce822aa712"
          onClick={
            userIsOnTrial()
              ? handleOpenFreeTrialModal
              : handleOpenDatasetResourceTypes
          }
        >
          <img
            alt=""
            className="button-icon hidden"
            loading="lazy"
            src="/images/user-black-thick.svg"
            width={70}
          />
          <div className="button-text">Add Resource</div>
        </div>
      </div>
      <div className="w-layout-vflex table-container">
        <div className="w-layout-hflex table-header">
          <div className="table-block table-block-very-big all-whiten-in-dark-mode">
            <h5 className="table-title">NAME</h5>
            <img
              alt=""
              className="user-table-sort-icon"
              loading="lazy"
              src="/images/arrow-up-down.svg"
            />
          </div>
          <div className="table-block table-block-medium all-whiten-in-dark-mode">
            <h5 className="table-title">TYPE</h5>
            <img
              alt=""
              className="user-table-sort-icon"
              loading="lazy"
              src="/images/arrow-up-down.svg"
            />
          </div>
          <div className="table-block table-block-big all-whiten-in-dark-mode">
            <h5 className="table-title">TOKENS</h5>
            <img
              alt=""
              className="user-table-sort-icon"
              loading="lazy"
              src="/images/arrow-up-down.svg"
            />
          </div>
          <div className="table-block table-block-large all-whiten-in-dark-mode">
            <h5 className="table-title">URL</h5>
            <img
              alt=""
              className="user-table-sort-icon hidden"
              loading="lazy"
              src="/images/arrow-up-down.svg"
            />
          </div>
          <div className="table-block table-block-smallest">
            <h5 className="table-title"></h5>
          </div>
        </div>

        {hasLoadedOnce && !loading && !datasetsToRender?.length && (
          <NoRecordFound />
        )}
        {!loading &&
          datasetsToRender?.map((item) => (
            <DatasetItem dataset={item} key={item.url} />
          ))}

        {loading && <IBLSpinLoader size={30} containerHeight={'100px'} />}
      </div>

      {datasets?.results && (
        <div
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          <IBLPagination
            currentPage={page}
            totalPages={numPages}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </div>
  );
}
