import { useParams } from 'react-router-dom';
import useAnonymousMode from '../iframe/useAnonymousMode';
import useIframeEvents from '../iframe/useIframeEvents';
import { useEffect, useState } from 'react';
import { axdTokenIsExpired, redirectToAuth } from '../../utils/auth';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../lib/redux/auth/slice';

export default function usePlatformRequest() {
  const pathParams = useParams();
  const ready = useSelector((state) => state.auth.ready);

  const redirectPath = `${window.location.pathname}${window.location.search}`;
  const redirectUrl = `${window.location.origin}`;
  const anonymous = useAnonymousMode();
  const { handleIframeMessage, notifyParentOnReady } = useIframeEvents();
  const dispatch = useDispatch();

  const handlePlatformRequest = () => {
    const currentTenant = localStorage.getItem('tenant');
    if (
      !anonymous &&
      (pathParams.platform !== currentTenant || axdTokenIsExpired())
    ) {
      localStorage.setItem('redirect-path', redirectPath);
      return redirectToAuth(true, redirectUrl, pathParams.platform);
    } else {
      //set tenant localstorage data when embedded
      if (anonymous) {
        localStorage.setItem('tenant', pathParams.platform);
      }
      dispatch(authActions.readyUpdated(true));
      return [];
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleIframeMessage);
    notifyParentOnReady();
    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, []);

  useEffect(() => {
    handlePlatformRequest();
  }, []);

  return { ready };
}
